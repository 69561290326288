import React from 'react';
import { Container } from './style';
import { StandardText } from '../../config/theme/globalStyles';
import { ActivityIndicator } from 'react-native-paper';
import THEME from '../../config/theme';
import { borderRadiusButtons } from '../../config/data';
import { useCustomTheme } from '../../contexts/useCustomTheme';
import { removeSansSerif } from '../../utils/index';
import {debounce} from "lodash";

const Button = ({
  colortitle,
  borderRadius,
  colorbutton,
  title,
  width,
  fontFamily,
  fontSize,
  margin,
  isLoading = false,
  disabled,
  padding,
  onPress,
  ...rest
}) => {
  const enabled = disabled ? !isLoading && !disabled : !isLoading;
  const { customColor, customFont, customInfo } = useCustomTheme();

  // Debounce the onPress function
  const debouncedOnPress = debounce(onPress, 500);

  return (
    <Container
      enabled={enabled}
      colorbutton={
        colorbutton
          ? colorbutton
          : customColor &&
            customColor.button &&
            customColor.button.backgroundApp
          ? customColor.button.backgroundApp
          : THEME.COLORS.PRIMARY_900
      }
      width={width}
      margin={margin}
      padding={padding}
      borderRadius={
        borderRadius
          ? borderRadius
          : customInfo && customInfo.borderRadius
          ? `${customInfo.borderRadius}px`
          : borderRadiusButtons
      }
      style={{ opacity: isLoading === true ? 0.5 : 1 }}
      onPress={debouncedOnPress}
      {...rest}
    >
      {isLoading ? (
        <ActivityIndicator
          color={
            colortitle
              ? colortitle
              : customColor && customColor.button && customColor.button.textApp
              ? customColor.button.textApp
              : THEME.COLORS.TEXT_BUTTON
          }
        />
      ) : (
        <StandardText
          fontFamily={
            fontFamily
              ? removeSansSerif(fontFamily)
              : customFont && customFont.fontFamilySubtitle
              ? removeSansSerif(customFont.fontFamilySubtitle)
              : THEME.FONTFAMILY.MEDIUM
          }
          style={{
            color: colortitle
              ? colortitle
              : customColor && customColor.button && customColor.button.textApp
              ? customColor.button.textApp
              : THEME.COLORS.TEXT_BUTTON,

            fontSize: fontSize
              ? fontSize
              : customFont && customFont.fontMD
              ? customFont.fontMD
              : THEME.FONTSIZE.MEDIUM,
          }}
        >
          {title}
        </StandardText>
      )}
    </Container>
  );
};

export default Button;

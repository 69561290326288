// YourComponent.js
import React, { useState, useEffect } from 'react';
import { View, Text, ActivityIndicator, StyleSheet, Linking, TouchableOpacity } from 'react-native';
import {createCheckout} from "../AdminPanel/Products/service/product-service";
import {SmallText, StandardText} from "../../config/theme/globalStyles";
import Button from "../../components/Button";
import THEME from '../../config/theme';
import {CommonActions} from "@react-navigation/native";

const PostCheckoutProduct = ({route, navigation}) => {
  const { data, productData } = route.params;
  const [loading, setLoading] = useState(true);
  const [paymentUrl, setPaymentUrl] = useState(null);
  const [subscriptionRes, setSubscriptionRes] = useState(null);

  useEffect(() => {
    createCheckout({ ...productData, ...data })
      .then(res => {
        if (productData.type !== 'plan') {
          const url = res?.data?.body?.checkouts[0]?.payment_url;
          setPaymentUrl(url);
          if (url) {
            Linking.openURL(url).catch(err => console.error('Failed to open URL:', err));
          }
        } else {
           if (res?.data?.status !== 200) {
             return;
           }
           setSubscriptionRes(res)
        }
      })
      .then(() => setLoading(false));
  }, [productData, data]);

  const getStatusComponent= () => {
    if (paymentUrl) {
      return (
        <StandardText style={styles.text}>
          Voce será redirecionado para a página de compra, caso isso não aconteça{' '}
          {paymentUrl && (
            <a href={paymentUrl}>
              <Text style={styles.link}>clique aqui</Text>
            </a>
          )}
        </StandardText>
      )
    }

    if (subscriptionRes) {
      return (
          <>
            <StandardText margin="1rem 0rem"> Assinatura efetuada com sucesso!</StandardText>
            <SmallText margin="0rem 0rem 3rem 0rem">Em caso de primeira compra verifique seu email para obter as credenciais de acesso.</SmallText>
            <Button
              title={"Fazer Login"}
              onPress={() =>
                navigation.dispatch(
                  CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'Login' }],
                  }))}
            >
            </Button>
          </>
      )
    }

    return (
      <StandardText margin="1rem 0rem">
        Erro ao durante o fluxo de compra, por favor confira seus dados e tente novamente.
      </StandardText>
    )
  }

  if (loading) {
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      {getStatusComponent()}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 16,
    textAlign: 'center',
  },
  link: {
    color: 'blue',
    textDecorationLine: 'underline',
  },
});

export default PostCheckoutProduct;
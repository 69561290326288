import React, { useState } from 'react';
import { Modal } from 'react-native';
import { SubTitle } from '../../config/theme/globalStyles';
import Button from '../Button';
import THEME from '../../config/theme';
import {
  ModalView,
  Input,
  ButtonContainer,
  Container,
  ErrorMessage,
  InfoMessage,
} from './style';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
} from 'firebase/firestore';
import { firestore } from '../../services/firebase';
import { useCustomTheme } from '../../contexts/useCustomTheme';
import { removeSansSerif } from '../../utils';

const NotificationModal = ({
  modalVisible,
  handleCloseModal,
  handleSendNotification,
  loading,
  setTitle,
  setContent,
  title,
  content,
}) => {
  const [error, setError] = useState('');

   const {
     customColor,
     customFont,
   } = useCustomTheme();

  const handleSend = async () => {
    if (!title || !content) {
      setError('Título e conteúdo são obrigatórios.');
      return;
    }
    try {
      const notificationsRef = collection(firestore, 'notifications');

      const lastDocQuery = query(
        notificationsRef,
        orderBy('sendDate', 'desc'),
        limit(1)
      );
      const querySnapshot = await getDocs(lastDocQuery);

      if (!querySnapshot.empty) {
        const lastDoc = querySnapshot.docs[0];
        const lastDocData = lastDoc.data();

        if (
          lastDocData.status === 'pending' ||
          lastDocData.status === 'processing'
        ) {
          setError(
            'Você tem uma notificação sendo enviada nesse momento, tente novamente mais tarde.'
          );

          return;
        }

        const lastSendDate = lastDocData.sendDate.toDate(); 
        const now = new Date();
        const timeDifference = now - lastSendDate;
        const minutesDifference = timeDifference / (1000 * 60);

        if (minutesDifference < 15) {
          setError(
            'O servidor só permite envio de notificações de 15 em 15 minutos.'
          );
          return;
        }
      }
      setError('');
      handleSendNotification();
    } catch (error) {
      console.error('Erro ao verificar o status das notificações:', error);
      setError('Ocorreu um erro ao verificar o status das notificações.');
    }
  };

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={modalVisible}
      onRequestClose={handleCloseModal}
    >
      <Container>
        <ModalView>
          <SubTitle
            padding="0rem 0rem 0.5rem 0rem"
            color={
              customColor && customColor.text && customColor.text.auth
                ? customColor.text.auth
                : THEME.COLORS.TEXT_ABOUT
            }
            fontFamily={
              customFont && customFont.fontFamilyTitle
                ? removeSansSerif(customFont.fontFamilyTitle)
                : THEME.FONTFAMILY.MEDIUM
            }
          >
            Painel de notificações:
          </SubTitle>
          <InfoMessage
            style={{
              color:
                customColor && customColor.text && customColor.text.auth
                  ? customColor.text.auth
                  : THEME.COLORS.TEXT_ABOUT,
              fontFamily:
                customFont && customFont.fontFamilyTitle
                  ? removeSansSerif(customFont.fontFamilyTitle)
                  : THEME.FONTFAMILY.MEDIUM,
            }}
          >
            Após clicar em enviar, sua notificação estará a caminho e será
            entregue em breve.
          </InfoMessage>

          <Input placeholder="Título" value={title} onChangeText={setTitle} />
          <Input
            placeholder="Conteúdo"
            value={content}
            onChangeText={setContent}
            multiline={true}
            numberOfLines={12}
          />
          {error ? <ErrorMessage>{error}</ErrorMessage> : null}

          <ButtonContainer>
            <Button
              title="Enviar"
              onPress={handleSend}
              isLoading={loading}
              fontFamily={
                customFont && customFont.fontFamilyText
                  ? removeSansSerif(customFont.fontFamilyText)
                  : THEME.FONTFAMILY.MEDIUM
              }
            />
            <Button title="Cancelar" onPress={handleCloseModal} />
          </ButtonContainer>
        </ModalView>
      </Container>
    </Modal>
  );
};

export default NotificationModal;

import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

const ProductViewComponent = ({ productData }) => {

  return (
    <View>
      <Text style={styles.nameText}>{productData?.name}</Text>
      <Text style={styles.descriptionText}>{productData?.description}</Text>
      <Text>Tipo: {productData?.type === 'plan' ? 'Assinatura' : 'Produto'}</Text>
      <Text>Preço: R$ {productData?.price}</Text>
      <Text>Número de parcelas: {productData?.paymentFractions}</Text>
      {productData?.type === 'plan' && (
        <>
          <Text>Frequência: {productData?.frequency} mês(es)</Text>
          <Text>Dias de Teste Grátis: {productData?.trialDays}</Text>
          <Text>Máx. Cobranças: {productData?.maxFrequency}</Text>
        </>
      )}
      {!!productData.expirationDate &&
        <Text>{`Expira em ${productData?.expirationDate} dias`}</Text>
      }
      <Text>Arquivado: {productData?.archived ? 'Sim' : 'Não'}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  nameText: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 5,
  },
  descriptionText: {
    fontSize: 14,
    color: "#666",
    marginBottom: 10,
  }
});

export default ProductViewComponent;

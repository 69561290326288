import React, { useState } from "react";
import { Octicons } from "@expo/vector-icons";
import { ViewButton, ViewText, ViewTextInput, ContentIcon, Container } from "../style";
import THEME from "../../../config/theme";
import { SmallText, SubTitle } from "../../../config/theme/globalStyles";
import Button from "../../../components/Button";
import { AlertBox } from "../../../components/AlertBox";
import TextInput from "../../../components/TextInput";
import FlatlistMultipleSelection from "../../ClickCard/FlatListMultipleSelection";
import { ScrollView, View } from "react-native";
import { Provider } from "react-native-paper";
import { deleteField } from "firebase/firestore";


const EditCardInformationModal = (props) => {
  const { card, plans, courses, products, isSaving, onClose, onSave } = props;
  const { title, archived, order, description } = card || {};

  const [showSaveSuccessAlert, setShowSaveSuccessAlert] = useState(false);
  

  const [updatedCard, setUpdatedCard] = useState({
    title,
    archived,
    order,
    description,
  });

  const [updatedCourses, setUpdatedCourses] = useState([...courses]);
  const [updatedPlans, setUpdatedPlans] = useState([...plans]);
  const [updatedProducts, setUpdatedProducts] = useState([...products]);

  const handleOnSaveClick = async () => {
    const plans = updatedPlans?.filter((plan) => plan?.selected);
    const coursesCard = updatedCourses?.filter((course) => course?.selected);
    const productsCard = updatedProducts?.filter((product) => product?.selected);

    // Criar um array de strings contendo apenas os productIds dos produtos selecionados
    const selectedProductIds = productsCard?.map(product => product?.productId);

    //it needs to be { plans: plans }, even if the name of the variable is equal, because if its only plans, it saves on wrong format on firestore
    await onSave({
      ...updatedCard,
      ...(plans?.length > 0 && { plans: plans }),
      ...(plans?.length <= 0 && { plans: deleteField() }),
      ...(coursesCard?.length > 0 && { coursesCard: coursesCard }),
      ...(coursesCard?.length <= 0 && { coursesCard: deleteField() }),
      ...(selectedProductIds?.length > 0 && { productIds: selectedProductIds }),
      ...(selectedProductIds?.length <= 0 && { productIds: deleteField() }),
    });
    setShowSaveSuccessAlert(true);
  };

  return (
    <Provider>
      <ScrollView style={{ backgroundColor: 'white' }}>
        <Container>
          <SubTitle padding="0rem 0rem 0.5rem 0rem" color="black">
            Alterar Informações:
          </SubTitle>
          <SmallText color='black'>
            Altere a ordem que esse card aparece na tela principal, título e
            descrição.
          </SmallText>
          <ViewTextInput>
            <TextInput
              color="black"
              backgroundColor="white"
              label="Ordem"
              placeholder="Digitar ordem"
              keyboardType="numeric"
              returnKeyType="go"
              value={updatedCard.order}
              onChange={({ target: { value } }) => {
                setUpdatedCard(() => ({
                  ...updatedCard,
                  order: Number(value),
                }));
              }}
              error={false}
            />
          </ViewTextInput>
          <ViewTextInput>
            <TextInput
              color="black"
              backgroundColor="white"
              label="Título"
              placeholder="Digitar título"
              keyboardType="default"
              returnKeyType="go"
              value={updatedCard.title}
              onChange={({ target: { value } }) => {
                setUpdatedCard(() => ({ ...updatedCard, title: value }));
              }}
              error={false}
            />
          </ViewTextInput>
          <ViewTextInput>
            <ViewText>
              <TextInput
                color="black"
                backgroundColor="white"
                label="Descrição"
                placeholder="digitar descrição"
                keyboardType="default"
                returnKeyType="go"
                value={updatedCard.description}
                onChange={({ target: { value } }) => {
                  setUpdatedCard(() => ({
                    ...updatedCard,
                    description: value,
                  }));
                }}
                error={false}
              />
            </ViewText>
          </ViewTextInput>

          <View>
            {plans?.length > 0 && (
              <>
                <SmallText color='black'>
                  Selecione quais planos Stripe liberam o acesso a esse card:
                </SmallText>
                <FlatlistMultipleSelection
                  items={updatedPlans}
                  setItems={setUpdatedPlans}
                  smallText="Planos de Assinatura Selecionados:"
                  footerText="Selecione os planos de assinatura"
                />
              </>
            )}
            {courses?.length > 0 && (
              <>
                <SmallText color='black'>
                  Selecione quais produtos Stripe que liberam o acesso a esse card:
                </SmallText>
                <FlatlistMultipleSelection
                  items={updatedCourses}
                  setItems={setUpdatedCourses}
                  smallText="Produtos Selecionados:"
                  footerText="Selecione os produtos"
                />
              </>
            )}
            {products?.length > 0 && (
              <>
                <SmallText color='black'>
                  Selecione quais produtos criados liberam o
                  acesso a esse card:
                </SmallText>
                <FlatlistMultipleSelection
                  items={updatedProducts}
                  setItems={setUpdatedProducts}
                  smallText="Produtos Selecionados:"
                  footerText="Selecione os produtos"
                />
              </>
            )}
          </View>
          <AlertBox
            message={'Alteração feita com sucesso! :)'}
            visible={showSaveSuccessAlert}
            onClose={onClose}
            leftButton={'OK'}
            leftButtonFunction={() => setShowSaveSuccessAlert(false)}
          />

          {archived !== undefined && (
            <>
              <ContentIcon
                onPress={() =>
                  setUpdatedCard(() => ({
                    ...updatedCard,
                    archived: !updatedCard.archived,
                  }))
                }
                backgroundColor={THEME.COLORS.BACKGROUND_ARCHIVE}
                style={{ padding: '0.5rem' }}
              >
                <Octicons
                  name={'archive'}
                  size={16}
                  color={THEME.COLORS.TEXT_ARCHIVE}
                />
                <SmallText
                  margin="0rem 0.2rem"
                  color={THEME.COLORS.TEXT_ARCHIVE}
                >
                  {`${
                    updatedCard.archived ? 'Publicar Card' : 'Arquivar Card'
                  }`}
                </SmallText>
              </ContentIcon>
              <SmallText color='black' margin="1rem 0rem 0rem 0rem">
                {`Seu card está ${
                  updatedCard.archived ? 'arquivado' : 'publicado'
                }!`}
              </SmallText>
              <SmallText color='black'>
                {`${
                  updatedCard.archived
                    ? 'Clique em "Publicar Card" para ficar visível para seus alunos.'
                    : 'Clique em "Arquivar" para ficar invisível para seus alunos.'
                }`}
              </SmallText>
            </>
          )}
          <ViewButton>
            <Button
              title={'Salvar'}
              isLoading={isSaving}
              onPress={handleOnSaveClick}
              margin="0rem 0.5rem"
            ></Button>
            <Button
              title={'Cancelar'}
              onPress={onClose}
              disabled={isSaving}
              margin="0rem 0.5rem"
            ></Button>
          </ViewButton>
        </Container>
      </ScrollView>
    </Provider>
  );
};

export default EditCardInformationModal;

import styled from 'styled-components/native';
import { RFValue } from "react-native-responsive-fontsize";
import THEME from '../../config/theme';

export const View = styled.View`
  padding: ${({ padding }) => RFValue(padding) || RFValue(20)}px;
  justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  flex: ${({ flex }) => flex};
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: ${({ width }) => width || window.innerWidth}px;
`;

export const ModalView = styled.View`
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  align-items: center;
`;

export const Container = styled.View`
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: 'rgba(0, 0, 0, 0.5)';
`;

export const Input = styled.TextInput`
  border-bottom-width: 1px;
  margin-bottom: 10px;
  padding: 8px;
  width: 100%;
  color: #2e2e2e;
`;

export const ButtonContainer = styled.View`
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  gap: 5px;
`;

export const ErrorMessage = styled.Text`
  color: red;
  margin: 0.5rem 0;
`;

export const InfoMessage = styled.Text`
  text-align: center;
  padding: 16px;
`;

export const StyledButton = styled.TouchableOpacity`
  align-self: ${({ alignSelf }) => alignSelf || "flex-end"};
`;

export const Text = styled.Text`
  font-size: ${(props) => RFValue(Number(props.fontSize))}px;
  font-family: ${({ fontFamily }) => fontFamily || THEME.FONTFAMILY.REGULAR};
  color: ${({ color }) => color || THEME.COLORS.TEXT_ABOUT};
  text-decoration-line: ${(props) => props.underline ? "underline" : "none"};
  text-align: ${(props) => props.textAlign ? props.textAlign : "center"};
  padding-vertical: ${(props) => props.padding ? RFValue(Number(props.padding)) : RFValue(4)}px;
  flex-shrink: 1;
`;

import React from "react";
import ReactPlayer from "react-player";
import THEME from "../../config/theme";
import { useCustomTheme } from '../../contexts/useCustomTheme';

const VideoPlayer = ({ video, previewVideo }) => {
  const src =
    video?.videoInfo?.url ||
    video?.pandaVideoMetadata?.video_player ||
    video?.link ||
    previewVideo ||
    "";

    const { customColor } = useCustomTheme();

  if (src.search(/youtube|youtu.be/) >= 0 || src.search(/firebasestorage.googleapis/) >= 0 || src.search(/vimeo/) >= 0) {
    return (
      <ReactPlayer
        url={src}
        playing={false}
        width={"100%"}
        controls={true}
        height={"100%"}
        config={{
          youtube: {
            playerVars: { origin: window.location.origin },
          },
          vimeo: {
            title: video?.title,
            playerOptions: { origin: window.location.origin },
          },
          file: {
            attributes: {
              controlsList: "nodownload",
            },
          },
        }}
      />
    );
  } else {
    return (
      <iframe
        id="panda-1"
        src={`${src}&color=${customColor && customColor.primary ? customColor.primary.substring(1) : THEME.COLORS.PRIMARY_900.substring(1)}`}
        style={{ border: 'none', position: 'absolute' }}
        allowFullScreen={true}
        width="100%"
        height="100%"
        allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
      ></iframe>
    );
  }
};

export default VideoPlayer;

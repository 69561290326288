import React from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import Button from "../../../components/Button";
import { useNavigation } from "@react-navigation/native";
import ProductViewComponent from "./ProductViewComponent";

const screenWidth = Dimensions.get("window").width;

const GenericProduct = ({ productData, onClose }) => {
  const navigation = useNavigation();

  const editOffers = () => {
    onClose()
    navigation.navigate('ProductConfig', { productId: productData.id })
  }

  return (
    <View style={styles.modalView}>
      <View style={{ width: '100%' }}>
        <ProductViewComponent productData={productData} />
        <View style={styles.buttonContainer}>
          <Button title="Editar Ofertas" onPress={() => editOffers()} />
          <Button title="Fechar" onPress={onClose} />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  modalView: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20,
    alignItems: 'center',
    width: screenWidth > 980 ? screenWidth * 0.6 : screenWidth * 0.9,
  },
  label: {
    fontWeight: 'bold',
    fontSize: 14,
    marginBottom: 4,
  },
  readOnlyText: {
    fontSize: 14,
    color: '#2e2e2e',
    marginBottom: 10,
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5,
    zIndex: 1,
  },
  closeButtonText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#000',
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    margin: 10

  },
});

export default GenericProduct;

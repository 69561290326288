import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import THEME from '../../../config/theme';
import Button from '../../../components/Button';
import { useNavigation } from "@react-navigation/native";
import Constants from "expo-constants";
import { ActivityIndicator, Modal, Portal, Provider } from "react-native-paper";
import CreateUserModal from "../../AdminPanel/AppUsers/CreateUserModal";
import { ViewHorizontal, Row, ViewTable, ContainerScroll } from "./styles";
import TextInput from '../../../components/TextInput';
import TouchableText from '../../../components/TouchableText';
import { Title, SmallText, SubTitle, StandardText, FooterText } from '../../../config/theme/globalStyles';
import { ResetPassword } from "../../Login/ResetPassword";
import View from '@expo/html-elements/build/primitives/View';
import { TextInput as Input } from 'react-native-paper';

const Table = ({ tableData, products, totalCount, loading, totalFiltered, handleSaveUser, handlePageChange, handleFilter, resetPaginationToggle, fetchAllUsersHandler }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [isCreateUserModalVisible, setIsCreateUserModalVisible] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [visible, setVisible] = useState(false);
    const [showDownloadAllModalVisible, setShowDownloadAllModalVisible] = useState(false);
    const [processingFile, setProcessingFile] = useState(false);
    const showModal = () => setVisible(true);
    const hideModal = () => setVisible(false);

    const containerStyle = {
        backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
        padding: "2rem",
        width: Math.round(window.innerWidth * 0.7),
        alignSelf: "center",
    };

    const { guruUserToken, pagarmeApiKey, stripeSecretKey } = Constants.manifest.extra;

    const navigation = useNavigation();

    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    };

    const columns = [
        {
            name: 'Nome',
            selector: row => row.Nome_Completo || '',
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.Email || '',
            sortable: true,
        },
        {
            name: 'Tipo de Acesso',
            selector: row => {
                const hasValidPlan = row.planTitle && row.plan !== "0";
                const hasValidProduct = !!row.productIds && row.productIds.some(product => {
                    const expirationDate = product.expirationDate?.toDate();
                    return product.productId && (!expirationDate || expirationDate > new Date());
                });
                return (hasValidPlan || hasValidProduct) ? 'Ativo' : 'Sem compras';
            },
            sortable: true,
        },
        ...(stripeSecretKey ? [{
            name: 'Plano Stripe',
            selector: row => (row.planTitle && row.plan !== "0") ? row.planTitle : "-",
            sortable: true,
        }] : []),
        ...(stripeSecretKey ? [{
            name: 'Cursos Stripe',
            selector: row => row.coursesTitle || '',
            sortable: true,
        }] : []),
        ...((guruUserToken || pagarmeApiKey) ? [{
            name: 'Produtos',
            selector: row => (row.productIds && row.productIds.length > 0) ? row.productIds.map(product => product.productId).join(', ') : '',
            sortable: true,
        }] : []),
        {
            name: 'Vídeos assistidos',
            selector: row => row.watchedVideos ? row.watchedVideos.length : 0,
            cell: row => (
                <ViewHorizontal>
                    <SmallText color="black">{row.watchedVideos ? row.watchedVideos.length : 0}</SmallText>
                    {row.watchedVideos && row.watchedVideos.length > 0 && (
                        <TouchableText
                            onPress={() => {
                                navigation.navigate("UsersAnalytics", {
                                    userId: row.id,
                                    userName: row.Nome_Completo || '',
                                })
                            }}
                            title={"Ver"}
                            textDecoration="underline"
                            color={"#767577"}
                            margin="0rem 1rem"
                        />
                    )}
                </ViewHorizontal>
            ),
            sortable: true,
        },
        ...((guruUserToken || pagarmeApiKey) ? [{
            name: 'Forma de Cadastro',
            selector: row => {
                if (row?.isSignUpFromApp) return "Aplicativo Mobile";
                if (row?.isSignUpFromAdminPanel) return "Manualmente";
                return '';
            },
            sortable: true,
        }] : []),
        ...((guruUserToken) ? [{
            name: 'Ações',
            cell: row => (
                <TouchableText
                    onPress={() => navigation.navigate("MeuPerfil", { userId: row.id, editUserAccess: true })}
                    title="Editar acesso"
                    fontFamily={THEME.FONTFAMILY.LIGHT}
                    fontSize={THEME.FONTSIZE.EXTRASMALL}
                    textDecoration={"underline"}
                    color="black"
                />
            ),
            sortable: true,
        }] : []),
    ];

    const customStyles = {
        rows: {
            style: {
                color: "#000000",
                fontFamily: THEME.FONTFAMILY.LIGHT,
                fontSize: THEME.FONTSIZE.EXTRASMALL
            },
        },
        headCells: {
            style: {
                color: "#000000",
                fontFamily: THEME.FONTFAMILY.MEDIUM,
                fontSize: THEME.FONTSIZE.EXTRASMALL
            },
        },
    };

    const handleDownloadCSV = (rows) => {


        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        };

        const selectedData = rows.map(row => {
            const hasValidPlan = row.planTitle && row.plan !== "0";
            const hasValidProduct = row.productIds && row.productIds.some(product => {
                const expirationDate = product.expirationDate?.toDate();
                return product.productId && (!expirationDate || expirationDate > new Date());
            });
            const tipoDeAcesso = (hasValidPlan || hasValidProduct) ? 'Ativo' : 'Sem compras';
            return {
                Nome_Completo: row.Nome_Completo || '',
                Tipo_De_Acesso: tipoDeAcesso,
                Email: row.Email || '',
                Celular: row.Celular || '',
                CPF: row?.CPF || '',
                Plano: row.planTitle || '',
                Cursos: row.coursesTitle || '',
                Produtos_Guru: (row.productIds && row.productIds.length > 0) ? row.productIds.map(product => product.productId).join(', ') : '',
                Vídeos_Assistidos: row.watchedVideos ? row.watchedVideos.length : 0,
                DataDeCadastro: row?.created_at ? row.created_at.toDate().toLocaleDateString('pt-BR', options) : '',
                FormaDeCadastro: row?.isSignUpFromApp ? "Aplicativo Mobile" : row?.isSignUpFromAdminPanel ? "Manualmente" : ''
            }
        });

        const csvContent = "data:text/csv;charset=utf-8," + selectedData
            .map(row => Object.values(row).join(','))
            .join('\n');

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'selected_data.csv');
        document.body.appendChild(link);
        link.click();
    };

    const downloadAllUsers = async () => {
        if (!processingFile) {
            setShowDownloadAllModalVisible(true);
            setProcessingFile(true);
            const response = await fetchAllUsersHandler();

            handleDownloadCSV(response);
            setProcessingFile(false);
            setShowDownloadAllModalVisible(false)
        } else {
            alert("Arquivo ja esta sendo processado")
        }

    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
    };

    const ExpandedComponent = ({ data }) => {

        return (
            <ViewTable>
                <SmallText textAlign="left" color="#2e2e2e" margin="0.5rem 0rem">Dados gerais:</SmallText>
                <FooterText textAlign="left" color="#2e2e2e">Celular: {data?.Celular || 'não informado'}</FooterText>
                <FooterText textAlign="left" color="#2e2e2e">CPF: {data?.CPF || 'não informado'}</FooterText>
                {data?.created_at && (
                    <FooterText textAlign="left" color="#2e2e2e">
                        Data de cadastro: {data.created_at.toDate().toLocaleDateString('pt-BR', options)}
                    </FooterText>
                )}
                {data?.productIds && data?.productIds.length > 0 && (
                    <>
                        <SmallText textAlign="left" color="#2e2e2e" margin="0.5rem 0rem">Produtos com acesso:</SmallText>
                        {data.productIds.map((product, index) => (
                            <FooterText textAlign="left" color="#2e2e2e" key={index}>
                                {product.productId} {product?.expirationDate ? `- Expira em: ${product?.expirationDate?.toDate().toLocaleDateString('pt-BR', options)}` : ''}
                            </FooterText>
                        ))}
                    </>
                )}
                {data?.planTitle && data?.plan !== "0" && (
                    <>
                        <SmallText textAlign="left" color="#2e2e2e" margin="0.5rem 0rem">Planos da Stripe com acesso:</SmallText>
                        <FooterText textAlign="left" color="#2e2e2e">{data.planTitle}</FooterText>
                    </>
                )}
                {data?.coursesTitle && (
                    <>
                        <SmallText textAlign="left" color="#2e2e2e" margin="0.5rem 0rem">Cursos da Stripe com acesso:</SmallText>
                        {data.coursesTitle.map((item, index) => (
                            <FooterText textAlign="left" color="#2e2e2e" key={index}>
                                {item}
                            </FooterText>
                        ))}
                    </>
                )}
            </ViewTable>
        );
    };

    return (
        <Provider>
            <ContainerScroll>
                <ViewHorizontal>
                    <Title textAlign="left" color="black">Usuários:</Title>
                    <Row gap="1rem">
                        <Button
                            onPress={() => downloadAllUsers()}
                            title={"Baixar CSV completo"}
                            fontFamily={THEME.FONTFAMILY.LIGHT}
                            fontSize={THEME.FONTSIZE.EXTRASMALL}
                        />
                        <Button
                            onPress={() => handleDownloadCSV(selectedRows)}
                            title={"Baixar CSV"}
                            fontFamily={THEME.FONTFAMILY.LIGHT}
                            fontSize={THEME.FONTSIZE.EXTRASMALL}
                        />
                        {(guruUserToken || pagarmeApiKey) &&
                            <Button
                                onPress={() => setIsCreateUserModalVisible(true)}
                                title={"+ Adicionar"}
                                fontFamily={THEME.FONTFAMILY.LIGHT}
                                fontSize={THEME.FONTSIZE.EXTRASMALL}
                            />
                        }
                        <Button
                            onPress={showModal}
                            title={"Redefinir senha"}
                            fontFamily={THEME.FONTFAMILY.LIGHT}
                            fontSize={THEME.FONTSIZE.EXTRASMALL}
                        />

                    </Row>
                </ViewHorizontal>
                <ViewHorizontal>
                    {(guruUserToken || pagarmeApiKey) &&
                        <SmallText textAlign="left" color="#2e2e2e">
                            Aqui você verá uma lista de todos os usuários que se cadastraram no seu aplicativo pelo formulário de cadastro do app Mobile (publicado na Apple Store e Google Play) ou que administradores deram acesso manualmente. Ou seja, eles podem só ter se cadastrado ou podem já ter efetuado compras. Para gestão das suas vendas e envio de e-mails a essas pessoas, baixe essa planilha no botão "baixar csv" e importe ela na sua plataforma de e-mail marketing.
                        </SmallText>
                    }
                </ViewHorizontal>
                <ViewHorizontal justifyContent="space-evenly">
                    <ViewTable>
                        <StandardText color="black">Total de usuários</StandardText>
                        <SubTitle color="#767577">{totalCount}</SubTitle>
                    </ViewTable>
                </ViewHorizontal>
                <ViewHorizontal justifyContent="space-evenly">
                    <TextInput
                        color="black"
                        backgroundColor="white"
                        width="80%"
                        placeholder="Pesquisar por email completo"
                        value={searchText}
                        onChangeText={text => setSearchText(text)}
                        onSubmitEditing={(event) => {
                            setSearchText(event.nativeEvent.text);
                            handleFilter(event.nativeEvent.text);
                        }}
                        right={searchText != '' && <Input.Icon
                            icon="alpha-x-circle-outline"
                            onPress={() => {
                                setSearchText('');
                                handleFilter('')
                            }}
                        />}
                    />
                    <Button
                        onPress={() => handleFilter(searchText)}
                        title={"Buscar"}
                        fontFamily={THEME.FONTFAMILY.LIGHT}
                        fontSize={THEME.FONTSIZE.EXTRASMALL}
                    />
                </ViewHorizontal>


                <DataTable
                    columns={columns}
                    data={tableData}
                    highlightOnHover
                    pointerOnHover
                    selectableRows
                    selectableRowsHighlight
                    selectableRowsVisibleOnly
                    customStyles={customStyles}
                    onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
                    onChangePage={handlePageChange}
                    pagination
                    paginationRowsPerPageOptions={[10]}
                    paginationServer
                    progressPending={loading}
                    progressComponent={
                        <ActivityIndicator
                            style={{
                                flex: 1,
                                backgroundColor: "#FFFFFF",
                                justifyContent: "center",
                            }}
                            color="#d3d3d3"
                            size="large"
                        />}
                    paginationDefaultPage={1}
                    paginationResetDefaultPage={resetPaginationToggle}
                    paginationTotalRows={totalFiltered}
                    paginationComponentOptions={paginationComponentOptions}
                    paginationIconFirstPage={false}
                    paginationIconLastPage={false}
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                />

                {isCreateUserModalVisible && products &&
                    <CreateUserModal
                        products={products}
                        visible={isCreateUserModalVisible}
                        onClose={() => setIsCreateUserModalVisible(false)}
                        onSave={handleSaveUser}
                    />
                }

                <Modal
                    visible={showDownloadAllModalVisible}
                    contentContainerStyle={containerStyle}
                >
                    <View>
                        <ActivityIndicator
                            style={{
                                flex: 1,
                                backgroundColor: "#FFFFFF",
                                justifyContent: "center",
                            }}
                            color="#d3d3d3"
                            size="large"
                        />
                        <br />
                        <br />
                        <SmallText>Processando arquivo, aguarde alguns minutos...</SmallText>
                    </View>
                </Modal>

                <Portal>
                    <Modal
                        visible={visible}
                        onDismiss={hideModal}
                        contentContainerStyle={containerStyle}
                    >
                        <ResetPassword isAdmin={true} />
                    </Modal>
                </Portal>
            </ContainerScroll>
        </Provider>
    );
};

export default Table;

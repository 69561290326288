import { getFunctions, httpsCallable } from "firebase/functions";
import {collection, doc, getDoc, getDocs} from "firebase/firestore";
import {firestore} from "../../../../services/firebase";

const functions = getFunctions();



export const createNewProduct = (data) => {
   const createNewProductHandler = httpsCallable(functions, "createProductHandler");
    return createNewProductHandler({ ...data });  
}


export const getProductById = (id) => {
    return getDoc(doc(firestore, 'products', id))
}


export const getOffers = (productId) => {
    return getDocs(collection(firestore, 'products', productId, 'offers'));
}

export const getOfferById = (productId, offerId) => {
    return getDoc(doc(firestore, 'products', productId, 'offers', offerId))
}

export const createNewOffer = (data) => {
    const createNewOfferHandler = httpsCallable(functions, "createNewOfferHandler");
     return createNewOfferHandler({ ...data });  
 }

export const createCheckout = (prouductData) =>  {
     const buyProductHandler = httpsCallable(functions, "buyProductHandler");

    return buyProductHandler({ ...prouductData });  
}

export const activateExistingOffer = ({productId, offerId}) => {
    const activateOfferHandler = httpsCallable(functions, "activateOfferHandler");
    if (!!offerId) {
        return activateOfferHandler({ productId, offerId })
    }
    
    return activateOfferHandler({ productId })

}


export const getPaymentFractions = ({price, paymentFractions}) => {
    const paymentFractionsHandler = httpsCallable(functions, "paymentFractionsHandler");

    return paymentFractionsHandler({ price, paymentFractions});
}
import React from "react";
import { Pressable } from "react-native";
import { Ionicons } from '@expo/vector-icons';
import Privacy from "../../components/Privacy";
import {
  ViewText,
  Container,
  ViewPressable,
  VerticalScroll,
  ViewHeading,
  ContentIcon,
} from "./style";
import { SubTitle } from "../../config/theme/globalStyles"
import { SafeAreaView } from "react-native";
import { privacyData as privacyDataClient } from "../../config/terms/clients";
import { privacyData as privacyDataBlix } from "../../config/terms/blix";
import { privacyData as privacyDataClientGeneral } from "../../config/terms/generalApp";
import { useNavigation } from "@react-navigation/native";
import Constants from "expo-constants";

export function TermsofUse({ isAdmin }) {

  const { guruUserToken, pagarmeApiKey } = Constants.manifest.extra;

  const privacyData = isAdmin ? privacyDataBlix : ((guruUserToken || pagarmeApiKey) ? privacyDataClientGeneral : privacyDataClient);

  const navigation = useNavigation();

  const Close = () => {
    return (
      <ContentIcon>
        <Ionicons
          name="close"
          size={26}
          color="black"
        />
      </ContentIcon>
    );
  };

  return (
    <SafeAreaView style={{ flexGrow: '1' }}>
      <Container>
        <ViewHeading>
          <ViewPressable></ViewPressable>
          <ViewText>
            <SubTitle
              color="black"
            >
              TERMOS DE USO E POLÍTICA DE PRIVACIDADE
            </SubTitle>
          </ViewText>
          <ViewPressable>
            <Pressable
              onPress={() => {
                navigation.goBack();
              }}
            >
              <Close></Close>
            </Pressable>
          </ViewPressable>
        </ViewHeading>
        <VerticalScroll>
          <Privacy privacyData={privacyData}></Privacy>
        </VerticalScroll>
      </Container>
    </SafeAreaView>
  );
}

import React, { useEffect, useState } from "react";
import { activateExistingOffer, createNewOffer, getOffers, getProductById } from "./service/product-service";
import { View, StyleSheet, Text, TouchableOpacity, Modal, Clipboard } from "react-native";
import CreateProductGenericForm from "./CreateProductGenericForm";
import ProductViewComponent from "./ProductViewComponent";
import Icon from 'react-native-vector-icons/Ionicons';
import Button from "../../../components/Button";


const ProductItem = ({ item, activeOffer, type, onActivate, copy, activateLoading }) => (
  <View
    style={[
      styles.itemContainer,
      activeOffer == item.id && styles.activeOfferContainer, // Apply the green border shadow conditionally
      type === 'OFFER' && styles.offer
    ]}>
    <View>
      <ProductViewComponent productData={item} />
      <TouchableOpacity
        style={styles.copyLinkContainer}
        onPress={() => {
          !!item.primaryCheckoutUrl ? copy(item.primaryCheckoutUrl) : copy(item.checkoutUrl);
        }}
      >
        <Text style={styles.copyLinkText}>{!!item.primaryCheckoutUrl ? item.primaryCheckoutUrl : item.checkoutUrl}</Text>
        <Icon name="copy-outline" size={20} style={styles.copyIcon} />
      </TouchableOpacity>
    </View>
    {/* Only show the activate button for offers */}
    {activeOffer != item.id && (
      <Button
        title="Tornar Padrão"
        style={styles.activateButton}
        isLoading={activateLoading}
        onPress={onActivate}>
      </Button>
    )}
  </View>
);

const ProductConfig = ({ route }) => {
  const { productId } = route.params;
  const [productData, setProductData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [createOfferModalVisible, setCreateOfferModalVisible] = useState(false);
  const [refreshIndex, setRefreshIndex] = useState(0);
  const [activateLoading, setActivateLoading] = useState(false);

  useEffect(() => {
    getProductById(productId).then((res) => {
      const product = res.data();
      setProductData(product);

      getOffers(productId).then((offersRes) => {
        const offers = [];
        offersRes.forEach((doc) => {
          offers.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setProductData({ ...product, offers: offers });
      }).finally(() => {
        setLoading(false);
      });
    });
  }, [productId, refreshIndex]);

  // Empty functions for buttons
  const activateOffer = (offerId) => {

    setActivateLoading(true);
    activateExistingOffer({ productId, offerId })
      .then(res => {
        setRefreshIndex(refreshIndex + 1)

      })
      .finally(() => setActivateLoading(false))
  };

  const addNewOffer = (data) => {
    return createNewOffer({ productId: productId, active: true, ...data })
      .finally(() => { setRefreshIndex(refreshIndex + 1) });
  };

  const openCreteOfferModal = () => {
    setCreateOfferModalVisible(true)
  }

  const onClose = () => {
    setCreateOfferModalVisible(false)
  }

  const copyToClipboard = (text) => {
    Clipboard.setString(text);
  };

  return (
    <View style={styles.container}>
      {/* Render the product */}
      <Text style={styles.headerText}>Produto</Text>
      {!!productData && <ProductItem item={productData} onActivate={() => activateOffer()} activeOffer={productData.activeOffer} type={'PRODUCT'} copy={copyToClipboard} activateLoading={activateLoading} />}

      {/* Add New Offer Button */}
      <Button title="Adicionar Nova Oferta" style={styles.addOfferButton} onPress={() => openCreteOfferModal()}>
      </Button>

      {/* Render the offers list */}
      {!!(productData?.offers) && (
        <>
          <Text style={styles.headerText}>Ofertas</Text>
          <View style={styles.offersContainer}>
            {productData.offers.map((item, index) => (
              <ProductItem
                key={index.toString()}
                item={item}
                activeOffer={productData.activeOffer}
                type={'OFFER'}
                onActivate={() => activateOffer(item.id)}
                copy={copyToClipboard}
                activateLoading={activateLoading}
              />
            ))}
          </View>
        </>
      )}

      {/* Modal for creating a new offer */}
      <Modal animationType="fade" visible={createOfferModalVisible} transparent={true}>
        <View style={styles.modalOverlay}>
          <View>
            <TouchableOpacity style={styles.closeButton} onPress={onClose}>
              <Text style={styles.closeButtonText}>X</Text>
            </TouchableOpacity>
            <CreateProductGenericForm onClose={onClose} saveHandler={addNewOffer} initialProduct={productData} />
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  headerText: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 10,
  },
  itemContainer: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "white",
    padding: 20,
    marginBottom: 15,
    borderRadius: 8,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 8,
    elevation: 2,
  },
  activeOfferContainer: {
    borderColor: "green", // Green border
    borderWidth: 2, // Thickness of the border
    shadowColor: "green", // Green shadow
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.5,
    shadowRadius: 8,
    elevation: 3, // Add elevation for shadow on Android
  },
  nameText: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 5,
  },
  descriptionText: {
    fontSize: 14,
    color: "#666",
    marginBottom: 10,
  },
  activateButton: {
    margin: "10px",
    width: "100%"
  },
  activateButtonText: {
    color: "white",
    fontWeight: "bold",
  },
  addOfferButton: {
    width: "100%",
    margin: "10px",
  },
  addOfferButtonText: {
    color: "white",
    fontWeight: "bold",
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
  },
  copyLinkContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    padding: 10,
    backgroundColor: '#e7f1ff',
    borderRadius: 5,
  },
  copyLinkText: {
    fontSize: 16,
    overflow: "hidden",
    color: '#333',
    flex: 1, // Allows text to take up remaining space
  },
  copyIcon: {
    marginLeft: 10, // Space between text and icon// Icon color
  },
  offersContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  offer: {
    width: "350px",
    marginLeft: "10px"
  },
  closeButton: {
    position: "relative",
    top: 30,
    right: 10,
    alignSelf: "flex-end",
    zIndex: 10
  },
  closeButtonText: {
    fontSize: 20,
    fontWeight: 'bold',
  },
});

export default ProductConfig;

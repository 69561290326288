import React, { useState, useEffect } from 'react';
import { View, Modal, TouchableOpacity, Text, Dimensions, ScrollView } from 'react-native';
import TextInput from "../../../components/TextInput";
import {
  FooterText,
  SubTitle,
} from "../../../config/theme/globalStyles";
import Button from "../../../components/Button";
import {
  ViewTextInput,
  ViewButton,
} from "./style";
import { getProductById } from '../../../services/guru';
import THEME from "../../../config/theme";
import CreateProductGenericForm from "./CreateProductGenericForm";
import { createNewProduct } from "./service/product-service";
import { useRemoteConfigContext } from '../../../contexts/useRemoteConfigContext';

const screenWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get('window').height;

const CreateProductModal = ({ isVisible, onClose, onCreate }) => {
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [productId, setProductId] = useState('');
  const [productInfo, setProductInfo] = useState('');
  const [offersInfos, setOffersInfos] = useState('');
  const [saveButtonVisible, setSaveButtonVisible] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const config = useRemoteConfigContext();

  const [state, setState] = useState({
    visibleAlert: false,
    message: null,
  });

  const showAlert = (message) => {
    setState({ visibleAlert: true, message: message });
  };

  useEffect(() => {
    if (showSuccessMessage) {
      const timer = setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000); // Esconde a mensagem após 3 segundos
      return () => clearTimeout(timer);
    }
  }, [showSuccessMessage]);

  const handleCreate = async () => {
    try {
      setLoadingSave(true);

      // Chama a função onCreate passando planData
      const result = await onCreate(productInfo, offersInfos, productId);

      if (result.success) {
        showAlert("Novo produto adicionado com sucesso!");
        setShowSuccessMessage(true);
        onClose();
      } else if (result.error) {
        showAlert(result.error);
      }

    } catch (error) {
      console.error('Erro ao criar produto:', error);
      showAlert('Erro ao criar produto');
    } finally {
      setLoadingSave(false);
    }
  };

  const findPlanAndOffers = async () => {
    try {
      setLoadingSearch(true);

      const infos = await getProductById(productId);
      setProductInfo(infos.product);
      setOffersInfos(infos.offers);
      setSaveButtonVisible(true);
    } catch (error) {
      console.error('Erro ao buscar informações do produto:', error);
      // Adicione lógica de tratamento de erro conforme necessário
    } finally {
      setLoadingSearch(false);
    }
  };

  return (
    <Modal
      animationType="fade"
      isVisible={isVisible}
      onBackdropPress={onClose}
      transparent={true}
    >
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
        }}
      >
        <View
          style={{
            maxWidth: screenWidth > 980 ? screenWidth * 0.6 : screenWidth * 0.9,
            backgroundColor: 'white',
            maxHeight: windowHeight * 0.8,
            borderRadius: 10,
            padding: 2,
            alignItems: 'center',
          }}
        >
          <ScrollView
            showsVerticalScrollIndicator={true}
            style={{ width: '100%', height: '100%' }}
          >
            <TouchableOpacity onPress={onClose} style={{ alignItems: "flex-end", paddingRight: 5 }}>
              <Text style={{
                fontSize: 20,
                fontWeight: 'bold'
              }}>x</Text>
            </TouchableOpacity>
            {!saveButtonVisible &&
              <SubTitle padding="0rem 0rem 0.5rem 0rem" color="#2e2e2e">
                Adicionar Produto no seu aplicativo:
              </SubTitle>
            }
            {config.paymentGateway === "PAGARME" ? (
              <CreateProductGenericForm onClose={onClose} saveHandler={createNewProduct}></CreateProductGenericForm>
            ) : (
              <View
                style={{
                  padding: 8,
                }}
              >
                <ViewTextInput>
                  <TextInput
                    placeholder="Digite o ID do Produto"
                    color="black"
                    backgroundColor="white"
                    value={productId}
                    onChangeText={(id) => setProductId(id)}
                  />
                  <FooterText padding="0.2rem 0rem" color="black">
                    {!saveButtonVisible ? "O ID do produto deve ser o código gerado na Guru Digital" : "Produto encontrado com sucesso!"}
                  </FooterText>
                  {state.visibleAlert && (
                    <FooterText color={THEME.COLORS.ALERT}>{state.message}</FooterText>
                  )}
                </ViewTextInput>
                <ViewButton>
                  {!saveButtonVisible &&
                    <Button
                      title="Buscar informações do produto"
                      isLoading={loadingSearch}
                      onPress={findPlanAndOffers}
                      fontSize={THEME.FONTSIZE.EXTRASMALL}
                    />
                  }
                </ViewButton>
              </View>
            )}
            {saveButtonVisible && (
              <ViewButton style={{ marginTop: '1rem' }}>
                <Button
                  title="Salvar"
                  isLoading={loadingSave}
                  onPress={handleCreate}
                  colorbutton={"#3FC380E6"}
                />
                <Button
                  title="Cancelar"
                  onPress={onClose}
                  colorbutton={"#FA5D5D"}
                  margin="0rem 0rem 0rem 1rem"
                />
              </ViewButton>
            )}
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};


export default CreateProductModal;